@charset "UTF-8";
@import "../mixin";
@import url(https://fonts.googleapis.com/css?family=Raleway:100,300,400,500,600,700,900);
@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
@import url(https://fonts.googleapis.com/css?family=Cabin:400,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700);
@font-face {
	font-family: 'icomoon';
	src: url('../../fonts/icomoon.eot?t7mzde');
	src: url('../../fonts/icomoon.eot?t7mzde#iefix') format('embedded-opentype'),
	url('../../fonts/icomoon.ttf?t7mzde') format('truetype'),
	url('../../fonts/icomoon.woff?t7mzde') format('woff'),
	url('../../fonts/icomoon.svg?t7mzde#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'HKNova';
	font-style: normal;
	font-weight: bold;
	src: url('/common/fonts/HKNova-Bold.woff2') format('woff2'),
	url('/common/fonts/HKNova-Bold.woff') format('woff'),
	url('/common/fonts/HKNova-Bold.otf') format('opentype'),
	url('/common/fonts/HKNova-Bold.ttf') format('truetype');
}
@font-face {
	font-family: 'HKNova';
	font-style: normal;
	font-weight: normal;
	src: url('/common/fonts/HKNova-Regular.woff2') format('woff2'),
	url('/common/fonts/HKNova-Regular.woff') format('woff'),
	url('/common/fonts/HKNova-Regular.otf') format('opentype'),
	url('/common/fonts/HKNova-Regular.ttf') format('truetype');
}
body {
	overflow-x: hidden;
}
.gl_header {
	background-color: #cc0300;
	color: #fff;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 105;
	&--in {
		max-width: 1030px;
		margin: 0 auto;
		padding: 0 15px;
		box-sizing: border-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-ms-flex-align: center;
		align-items: center
	}
	&--link {
		display: inline-block;
		text-decoration: none;
		font-size: 25px;
		font-family: raleway, sans-serif;
		color: #fff
	}
	&--catch {
		font-size: 10px;
		letter-spacing: 1px;
		line-height: 1.3;
		margin: 0 0 0 20px
	}
}
.main {
	overflow: hidden;
	position: relative;
	color: #000;
	height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
	&:before {
		content: "";
		background: url(/images/index-bg4.jpg);
		background-size: cover;
		opacity: 0.2;
		display: block;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		position: absolute;
		-ms-transform: scale(1.1, 1.1);
		transform: scale(1.1, 1.1);
		animation: opening 2s forwards
	}
	&--iframe {
		position: relative;
		z-index: 100;
		width: 750px;
		height: 462px;
		transition: 0.6s 0.3s;
		opacity: 0;
		&.is_show {
			opacity: 1;
		}
	}
	&--in {
		max-width: 1030px;
		margin: 0 auto;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: center;
		align-items: center;
		position: relative;
		min-height: calc(100vh - 56px);
		padding: 50px 0;
		box-sizing: border-box
	}
	&--content {
		max-width: 575px;
		position: relative;
		text-align: center;
	}
	&--ttl {
		font-size: 40px;
		font-weight: 600;
		margin: 0 0 50px;
		font-family: raleway, sans-serif
	}
	&--ttl_description {
		font-size: 16px;
	}
	&--description {
		font-size: 20px;
		line-height: 2;
		letter-spacing: .5px;
		margin: 0 0 30px
	}
	&--canvas {
		position: absolute;
		left: 0;
		top: 0;
	}
}
.form_box {
	&--input {
		display: block;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		width: 100%;
		height: 50px;
		line-height: 50px;
		font-size: 16px;
		color: #333;
		padding: 0 10px;
		border-radius: 3px;
		box-sizing: border-box;
		margin: 0 0 30px;
		background-color: #fff;
		background-color: rgba(255, 255, 255, .8);
		border: 3px solid $colorRed;
		&:focus {
			box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
		}
	}
	&--radio_box {
		display: block;
		margin: 0 auto 50px auto;
		text-align: left;
		width: 400px;
	}
	&--radio_label {
		cursor: pointer;
		display: block;
		margin: 0 0 25px 0;
		&:last-child {
			margin-bottom: 0;
		}
	}
	&--text {
		border: 0;
		width: 100%;
		height: 45px;
		font-size: 16px;
		border-radius: 3px;
		padding: 0 10px;
		margin: 15px 0 0 0;
		box-sizing: border-box;
		transition: 0.15s;
		&:focus {
			box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
		}
	}
	&--radio_btn {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		position: relative;
		padding: 0 0 0 20px
	}
	&--radio_btn:before {
		content: "";
		display: block;
		position: absolute;
		border: 2px solid #fff;
		border-radius: 50%;
		left: 0;
		top: -12px;
		width: 14px;
		height: 14px
	}
	&--radio_btn:after {
		content: "";
		display: block;
		position: absolute;
		left: 4px;
		top: -8px;
		width: 10px;
		height: 10px;
		background-color: #cc0300;
		border-radius: 50%;
		transition: .15s;
		-ms-transform: scale(0, 0);
		transform: scale(0, 0)
	}
	&--radio_btn:checked:after {
		-ms-transform: scale(1, 1);
		transform: scale(1, 1)
	}
	&--submit {
		border-radius: 5px;
		background-color: #cc0300;
		color: #fff;
		width: 300px;
		height: 55px;
		line-height: 55px;
		font-size: 18px;
		text-align: center;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		border: 0;
		padding: 0;
		box-shadow: 0 5px 0 #990200;
		cursor: pointer;
		position: relative;
		top: 0;
		transition: .15s;
		font-weight: 800
	}
	&--submit:before {
		content: "";
		display: block;
		width: 10px;
		height: 10px;
		background-color: green
	}
	&--submit:hover {
		box-shadow: 0 0 0 #990200;
		top: 5px
	}
	&--attention {
		color: #fff;
		display: block;
		margin: 0 0 80px
	}
	&--attention:before {
		content: '>';
		display: inline-block;
		-ms-transform: scale(.4, 1.1);
		transform: scale(.4, 1.1);
		position: relative;
		top: -1px;
		margin: 0 5px 0 0
	}
}
.gl_nav {
	margin: 0 0 0 80px;
	height: 56px;
	&--link {
		color: #fff;
		font-size: 14px;
		text-decoration: none;
		font-weight: 700;
		position: relative;
		display: block;
		transition: .15s;
		padding: 15px 0
	}
	&--link:before {
		content: "";
		display: block;
		border-style: solid;
		border-width: 6px 5px 0;
		border-color: #fff transparent transparent transparent;
		bottom: 1px;
		position: absolute;
		left: calc(50% - 3px);
		transition: .15s
	}
	&--link:hover {
		color: #000
	}
	&--link:hover:before {
		bottom: -2px;
		border-color: #000 transparent transparent transparent
	}
}
@keyframes opening {
	0% {
		transform: scale(1.1, 1.1)
	}
	10% {
		transform: scale(1.1, 1.1)
	}
	100% {
		transform: scale(1, 1)
	}
}
.opening {
	position: absolute;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	&--bg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100vw;
		height: 100vh;
		background-color: #000;
		opacity: 1;
		&.animation {
			animation: bg 8s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
		}
	}
	&--logo {
		position: absolute;
		left: calc(50% - 300px);
		top: calc(50% - 90px);
		z-index: 1;
		font-family: 'HKNova', sans-serif;
		width: 600px;
		opacity: 0;
		transform: scale(1.1, 1.1);
		color: #fff;
		font-size: 160px;
		font-weight: normal;
		&.animation {
			animation: logo 8s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
		}
	}
	&--catch {
		font-size: 33px;
		color: #fff;
		text-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
		text-align: center;
		display: block;
		position: absolute;
		left: calc(50% - 354px);
		font-weight: 600;
		top: 61%;
		letter-spacing: 7px;
		transform: scale(1.1, 1.1);
		opacity: 0;
		z-index: 10;
		&.animation {
			animation: catch 8s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
		}
	}
	&--attention {
		font-size: 40px;
		margin: 0 10px 0 0;
	}
	@keyframes logo {
		0% {
			transform: scale(1.1, 1.1);
			opacity: 0;
		}
		15% {
			opacity: 1;
			transform: scale(1, 1);
		}
		100% {
			opacity: 1;
			transform: scale(1, 1);
		}
	}
	@keyframes bg {
		0% {
			opacity: 1;
		}
		15% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}
	@keyframes catch {
		0% {
			transform: scale(1.1, 1.1);
			opacity: 0;
		}
		15% {
			opacity: 1;
			transform: scale(1, 1);
		}
		100% {
			opacity: 1;
			transform: scale(1, 1);
		}
	}
}
.sidebar_left {
	position: fixed;
	left: 50px;
	top: calc(50% - 44px);
	z-index: 2;
	&--link {
		display: block;
		text-decoration: none;
		color: #fff;
		margin: 30px 0 0 0;
		&:first-child {
			margin-top: 0;
		}
	}
	&--icon {
		font-size: 16px;
		&:before {
			text-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
		}
		&-fb {
			&:before {
				content: $iconFb;
				@include icon;
			}
		}
		&-tw {
			&:before {
				content: $iconTw;
				@include icon;
			}
		}
		&-in {
			&:before {
				content: $iconIn;
				@include icon;
			}
		}
	}
}
.oh {
	width: 100vw;
	overflow: hidden;
}
.wrap {
	width: calc(200vw - 110px);
	display: flex;
	position: relative;
	transition: 0.5s cubic-bezier(0.19, 1, 0.22, 1);
	left: 0;
	&.animation {
		left: calc(-100vw + 110px);
	}
}
.main_content {
	position: relative;
	overflow: hidden;
	width: 100vw;
	left: 0;
}
.how, .meet {
	max-width: 1000px;
	margin: 0 auto;
	position: relative;
	color: #333;
	padding: 150px 0;
	&--ttl {
		font-family: 'HKNova', sans-serif;
		font-size: 60px;
		line-height: 1.3;
		margin: 0 0 30px 0;
	}
	&--sub_ttl {
		font-size: 30px;
		letter-spacing: 3px;
		line-height: 1.5;
		margin: 0 0 15px 0;
	}
	&--description {
		font-size: 16px;
		line-height: 2;
		letter-spacing: 3px;
		font-weight: 500;
		margin: 0 0 50px 0;
	}
	&--link_btn {
		text-decoration: none;
		display: inline-block;
		padding: 30px 100px;
		background-color: $colorRed;
		color: #fff;
		font-weight: 500;
		font-size: 16px;
		box-shadow: 8px 8px 0px #f1f1f1;
		position: relative;
		left: 0;
		top: 0;
		transition: 0.5s cubic-bezier(0.19, 1, 0.22, 1);
		&:hover {
			background-color: darken($colorRed, 10%);
			left: 8px;
			top: 8px;
			box-shadow: 0px 0px 0px #f1f1f1;
		}
	}
}
.create {
	background: #f1f1f1;
	padding: 150px 0;
	text-align: center;
	&--inner {
		max-width: 650px;
		margin: 0 auto;
	}
	&--ttl {
		font-family: 'HKNova', sans-serif;
		font-size: 60px;
		line-height: 1.3;
		margin: 0 0 30px 0;
	}
	&--sub_ttl {
		font-size: 30px;
		letter-spacing: 3px;
		line-height: 1.5;
		margin: 0 0 15px 0;
	}
	&--description {
		font-size: 16px;
		line-height: 2;
		letter-spacing: 3px;
		font-weight: 500;
		margin: 0 0 50px 0;
	}
	&--link_btn {
		text-decoration: none;
		display: inline-block;
		padding: 30px 100px;
		background-color: $colorRed;
		color: #fff;
		font-weight: 500;
		font-size: 16px;
		box-shadow: 8px 8px 0px #ccc;
		cursor: pointer;
		position: relative;
		left: 0;
		top: 0;
		transition: 0.5s cubic-bezier(0.19, 1, 0.22, 1);
		&:hover {
			background-color: darken($colorRed, 10%);
			left: 8px;
			top: 8px;
			box-shadow: 0px 0px 0px #f1f1f1;
		}
	}
	&--link {
		color: $colorRed;
		transition: 0.15s;
		font-size: 14px;
		&:hover {
			color: darken($colorRed, 10%);
		}
	}
}
.meet {
	background: url('/images/sumi.jpg') no-repeat right top;
	background-size: cover;
	max-width: 100%;
	&--content {
		max-width: 1000px;
		margin: 0 auto;
	}
}
.user_choose {
	width: 100%;
	height: 750px;
	&--nav {
		width: 100%;
		display: flex;
		position: relative;
	}
	&--bg {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background-size: cover;
	}
	&--card {
		width: 33.333%;
		height: 750px;
		box-sizing: border-box;
		padding: 200px 20px 0;
		position: relative;
		display: flex;
		justify-content: center;
		transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
		cursor: pointer;
		&:before {
			content: "";
			display: block;
			position: absolute;
			left: 0;
			transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
			top: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.6);
		}
		&:hover {
			&:before {
				background-color: rgba(0, 0, 0, 0.2);
			}
		}
		&-beginner {
			background: url('/images/user-choose-bg-01.jpg') no-repeat center center;
			background-size: cover;
		}
		&-advanced {
			background: url('/images/user-choose-bg-02.jpg') no-repeat center center;
			background-size: cover;
			position: relative;
		}
		&-experience {
			background: url('/images/user-choose-bg-03.jpg') no-repeat center center;
			background-size: cover;
		}
		&-close {
			pointer-events: none;
			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				background: rgba(0, 0, 0, 0.9);
				width: 100%;
				height: 100%;
				color: #fff;
				z-index: 100;
			}
			&:after {
				content: 'COMING SOON';
				position: absolute;
				width: 100%;
				text-align: center;
				top: calc(50% - 0.5em);
				font-family: 'HKNova', sans-serif;
				color: #fff;
				font-weight: 600;
				font-size: 45px;
				z-index: 101;
				transform: rotate(15deg);
				letter-spacing: 2px;
			}
			&:hover {
				&:before {
					background: rgba(0, 0, 0, 0.9);
				}
			}
		}
		&.active {
			animation: active 1.3s cubic-bezier(0.23, 1, 0.32, 1) forwards;
		}
		&.remove {
			animation: remove 1.3s cubic-bezier(0.23, 1, 0.32, 1) forwards;
		}
		@keyframes active {
			0% {
				height: 750px;
				width: 40%;
			}
			30% {
				height: 750px;
				width: 80%;
			}
			100% {
				height: 300px;
				width: 80%;
			}
		}
		@keyframes remove {
			0% {
				height: 750px;
				width: 40%;
			}
			30% {
				height: 750px;
				width: 80%;
			}
			100% {
				height: 300px;
				width: 80%;
			}
		}
	}
	&--content {
		position: relative;
		z-index: 1;
	}
	&--ttl {
		font-size: 50px;
		color: #fff;
		line-height: 1.5;
		letter-spacing: 3px;
		opacity: 0;
		transform: scale(1.1, 1.1);
		font-family: 'HKNova', sans-serif;
		&.animation {
			animation: logo 8s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
		}
	}
	&--description {
		color: #fff;
		font-size: 14px;
		line-height: 2;
		letter-spacing: 5px;
		transform: scale(1, 1);
		opacity: 0;
		transition: 2s cubic-bezier(0.075, 0.82, 0.165, 1);
	}
	&--catch {
		color: #fff;
		font-size: 14px;
		line-height: 1.5;
		letter-spacing: 5px;
		opacity: 0;
		transform: scale(1.1, 1.1);
		margin: 0 0 20px 0;
		&.animation {
			animation: logo 8s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
		}
	}
	&--link_btn {
		text-decoration: none;
		color: #fff;
		font-size: 16px;
	}
	&--card_link {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
	}
}
.external_content {
	width: calc(100vw - 110px);
	&--back_btn {
		position: fixed;
		left: 150px;
		top: calc(50% - 10px);
		color: #000;
		text-decoration: none;
		display: flex;
		align-items: center;
		font-weight: bold;
		font-size: 19px;
		transition: 0.15s;
		opacity: 0;
		pointer-events: none;
		cursor: pointer;
		&:before {
			content: '';
			display: block;
			border-bottom: 5px solid $colorRed;
			border-right: 5px solid $colorRed;
			transform: rotate(135deg);
			width: 10px;
			height: 10px;
			margin: 0 10px 0 0;
		}
		&:hover {
			color: $colorRed;
		}
		&.is_show {
			opacity: 1;
			pointer-events: auto;
		}
	}
}
.howto {
	padding: 50px 0px;
	color: #333;
	&--ttl {
		font-family: 'HKNova', sans-serif;
		font-size: 60px;
		text-align: center;
		display: block;
		line-height: 1.3;
		margin: 0 0 30px 0;
	}
	&--catch {
		font-size: 30px;
		letter-spacing: 3px;
		line-height: 1.5;
		margin: 0 0 50px 0;
		text-align: center;
	}
	&--content {
		max-width: 1000px;
		margin: 0 auto;
		position: relative;
		padding: 0 0 0 700px;
		box-sizing: border-box;
		min-height: 400px;
		&-small {
			padding-left: 500px;
			margin-top: 50px;
			min-height: 250px;
			.howto {
				&--box_img {
					width: 450px;
					height: 250px;
				}
			}
		}
		&-right_pic {
			padding: 0 700px 0 0;
			.howto {
				&--box_img {
					left: auto;
					right: 0;
				}
			}
		}
	}
	&--box_img {
		width: 650px;
		display: block;
		position: absolute;
		left: 0;
		top: 0;
	}
	&--box_ttl {
		font-size: 20px;
		color: $colorRed;
		line-height: 1.5;
		letter-spacing: 5px;
		margin: 0 0 15px 0;
	}
	&--description {
		line-height: 2;
	}
	&--box {
		margin: 0 0 100px 0;
		&-red {
			background-color: rgba(231, 100, 99, 0.2);
			padding: 100px 0;
		}
		&-last {
			margin-bottom: 0;
		}
	}
	&--back_btn {
		font-size: 13vw;
		color: #333;
		text-align: center;
		display: block;
		font-weight: 600;
		text-decoration: none;
		padding: 100px 0;
	}
}
.generate {
	padding: 150px 0;
	background-color: #333;
	&--content {
		max-width: 1000px;
		margin: 0 auto;
		color: #fff;
	}
	&--ttl {
		@extend .how--ttl;
		text-align: center;
	}
	&--description {
		@extend .how--sub_ttl;
		margin: 0 0 80px 0;
		text-align: center;
	}
	&--box {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&--box_pic {
		margin: 0 50px 0 -250px;
	}
	&--box_content {
		max-width: 450px;
	}
	input[type=submit] {
		-webkit-appearance: none;
		border: 0px solid transparent;
		text-decoration: none;
		display: inline-block;
		padding: 20px 100px;
		margin: 30px 0 0 0;
		letter-spacing: 2px;
		background-color: $colorRed;
		color: #fff;
		font-weight: 500;
		font-size: 25px;
		box-shadow: 8px 8px 0px #000;
		cursor: pointer;
		position: relative;
		left: 0;
		top: 0;
		transition: 0.5s cubic-bezier(0.19, 1, 0.22, 1);
		&:hover {
			background-color: darken($colorRed, 10%);
			left: 8px;
			top: 8px;
			box-shadow: 0px 0px 0px #000;
		}
	}
	input[type=text] {
		-webkit-appearance: none;
		border: 0px solid transparent;
		width: 100%;
		display: block;
		font-size: 16px;
		padding: 10px;
		margin: 0 0 15px 0;
		letter-spacing: 1px;
		color: #333;
		&:focus {
			box-shadow: 0 0 10px #fff;
		}
	}
	input[type=checkbox] {
		-webkit-appearance: none;
		border: 2px solid #fff;
		border-radius: 50%;
		width: 15px;
		height: 15px;
		position: relative;
		top: 5px;
		&:before {
			transition: 0.15s;
			content: "";
			display: block;
			width: 7px;
			height: 7px;
			border-radius: 50%;
			background-color: #fff;
			position: relative;
			opacity: 0;
			top: 2px;
			left: 2px;
		}
		&:checked {
			&:before {
				opacity: 1;
			}
		}
	}
	&--small_label {
		font-size: 12px;
		margin: 0 0 0 5px;
	}
	&--label {
		display: block;
		letter-spacing: 1px;
		margin: 0 0 15px 0;
		cursor: pointer;
	}
}
